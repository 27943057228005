const UNPROTECTED_ROUTES = ["/login", "/register", "/privacy"];

const PROTECTED_ROUTES = [
  "/settings",
  "/subscribers",
  "/subscriptions",
  "/extensions",
];

export { UNPROTECTED_ROUTES, PROTECTED_ROUTES };
