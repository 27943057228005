import Image from "next/image";

interface LoaderProps {
  width?: number;
  height?: number;
  isWhite?: boolean;
}

function Loader({ width = 50, height = 50, isWhite }: LoaderProps) {
  if (isWhite)
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Image
          className="mx-auto motion-safe:animate-spin"
          src="/images/starlo_stars_white_centered.svg"
          alt="Starlo favicon"
          width={width}
          height={height}
          priority
        />
      </div>
    );
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Image
        className="mx-auto motion-safe:animate-spin"
        src="/images/starlo_stars_black_centered.svg"
        alt="Starlo favicon"
        width={width}
        height={height}
        priority
      />
    </div>
  );
}

export default Loader;
