import { DefaultSeoProps } from "next-seo";

import { BASE_URL } from "constants/redirects";

const config: DefaultSeoProps = {
  title: "Starlo - Social website builder",
  description:
    "Update your website as fast as your social media. Starlo is a website for creators that makes it outrageously easy to post. Share text, essays, images, videos, and more.",
  openGraph: {
    url: BASE_URL,
    title: "Starlo - Social website builder",
    description:
      "Update your website as fast as your social media. Starlo is a website for creators that makes it outrageously easy to post. Share text, essays, images, videos, and more.",
    images: [
      {
        url: `${BASE_URL}/starlo-meta-image.jpg`,
        width: 1200,
        height: 630,
        alt: "Starlo - Build your personal social profile to connect with your community. Share your story, your way.",
        type: "image/jpeg",
      },
    ],
    siteName: "Starlo",
    type: 'website',
  },
  twitter: {
    handle: "@starlo",
    site: "@starlo",
    cardType: "summary_large_image",
  },
};

export default config;
