import { db } from "utils/firebase";
import {
  setDoc,
  updateDoc,
  doc,
  getDoc,
  deleteDoc,
  query,
  collection,
  where,
  getDocs,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";

import { User } from "models/User";
import { ErrorWithCode } from "models/ErrorWithCode";
import { ERROR_CODES } from "constants/errorCodes";
import { WaitlistUser } from "models/WaitlistUser";
import { BLOCKED_DOMAINS } from "constants/blockedNames";
import { subscribeToUser } from "./subscription";
import { STARLO_ID } from "constants/customOnboarding";

// User is added on first login
const addUser = async ({ uid, details }: { uid: string; details?: User }) => {
  const { email, ...publicDetails } = details;

  try {
    await setDoc(doc(db, "users", uid), {
      ...details,
      role: "user",
      username: uid,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }, { merge: true });

    await setDoc(doc(db, "publicUsers", uid), {
      ...publicDetails,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }, { merge: true });

    Promise.resolve();
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

const updateUser = async ({
  docId,
  details,
}: {
  docId: string;
  details?: User;
}) => {
  const { email, ...publicDetails } = details;
  const { username: privateUsername, ...withoutUsernameDetails } = details;
  const {
    username: publicUsername,
    email: emailWithoutUsername,
    ...withoutUsernamePublicDetails
  } = details;

  try {
    const userRef = doc(db, "users", docId);
    const publicUserRef = doc(db, "publicUsers", docId);
    const docSnap = await getDoc(userRef);
    const publicDocSnap = await getDoc(publicUserRef);
    const usernameSnap = await getDoc(doc(db, "usernames", details.username));

    if (docSnap.exists()) {
      if (details?.username && docSnap.data()?.username !== details?.username) {
        if (usernameSnap.exists()) {
          throw new Error("Username already exists");
        }

        // Update usernames
        if (docSnap.data()?.username !== undefined) {
          await deleteDoc(doc(db, "usernames", docSnap.data().username));
        }

        await setDoc(doc(db, "usernames", details.username), {
          uid: docId,
          updatedAt: serverTimestamp(),
        });

        // Update profiles
        await updateDoc(userRef, {
          ...details,
          updatedAt: serverTimestamp(),
        });

        await updateDoc(publicUserRef, {
          ...publicDetails,
          updatedAt: serverTimestamp(),
        });

        // Revalidate profile
        await fetch(`/api/revalidate?path=`);

        //Ping google bot
        fetch(`/api/pingGoogleSitemap`);
      } else {
        // Update profiles without username
        if (docSnap.data()?.username === undefined) {
          await setDoc(userRef, {
            ...withoutUsernameDetails,
            username: docId,
            updatedAt: serverTimestamp(),
          });
        } else {
          await updateDoc(userRef, {
            ...withoutUsernameDetails,
            updatedAt: serverTimestamp(),
          });
        }

        if (publicDocSnap.exists()) {
          await updateDoc(publicUserRef, {
            ...withoutUsernamePublicDetails,
            updatedAt: serverTimestamp(),
          });

          // Revalidate profile
          await fetch(`/api/revalidate?path=`);

          //Ping google bot
          fetch(`/api/pingGoogleSitemap`);
        } else {
          await setDoc(publicUserRef, {
            ...withoutUsernamePublicDetails,
            username: docId,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });

          // Revalidate profile
          await fetch(`/api/revalidate?path=`);

          //Ping google bot
          fetch(`/api/pingGoogleSitemap`);
        }
      }
    } else {
      await addUser({ uid: docId, details });
    }

    Promise.resolve();
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

const updateUserLastPost = async ({ docId }: { docId: string }) => {
  try {
    const userRef = doc(db, "users", docId);
    const publicUserRef = doc(db, "publicUsers", docId);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      const lastPostedAt = serverTimestamp();
      await updateDoc(userRef, {
        lastPostedAt,
      });

      await updateDoc(publicUserRef, {
        lastPostedAt,
      });

      const subscribers = await getDocs(
        query(
          collection(db, "subscriptions"),
          where("subscriptionId", "==", docId)
        )
      );

      if (subscribers?.docs?.length !== 0) {
        const updateSubscribersBatch = writeBatch(db);

        subscribers.forEach((subscriber) => {
          if (subscriber?.data()?.subscriberId) {
            updateSubscribersBatch.update(
              doc(
                db,
                "subscriptions",
                `${subscriber.data()?.subscriberId}_${docId}`
              ),
              { lastPostedAt }
            );
          }
        });

        await updateSubscribersBatch.commit().then(() => Promise.resolve());
      }
      Promise.resolve();
    } else {
      throw new Error("User does not exist");
    }
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

const getUser = async (uid: string): Promise<User> => {
  try {
    const userRef = doc(db, "users", uid);
    const docSnap = await getDoc(userRef);

    if (!docSnap.exists()) return null;
    return docSnap.data() as User;
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

const checkUsernameExists = async (username: string): Promise<boolean> => {
  try {
    if (BLOCKED_DOMAINS.includes(username)) return true;
    return (await getDoc(doc(db, "usernames", username))).exists();
  } catch (err) {
    console.error(err);
    return false;
  }
};

const doesUserExist = async (email: string): Promise<boolean> => {
  try {
    return !(
      await getDocs(query(collection(db, "users"), where("email", "==", email)))
    ).empty;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const isUserOnboarded = async (userId: string) => {
  try {
    const userRef = doc(db, "users", userId);
    const docSnap = await getDoc(userRef);

    if (!docSnap.exists()) return false;
    return docSnap.get("isOnboarded") ?? false;
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

const addToWaitlist = async (email: string) => {
  return await fetch("https://api.getwaitlist.com/api/v1/waiter", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      api_key: process.env.NEXT_PUBLIC_WAITLIST_API_KEY,
    }),
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        console.error(`${response.status}: ${err?.error_string ?? err}`);
        throw new ErrorWithCode(ERROR_CODES.waitlistRequestFailed);
      });
    }

    return response.json();
  });
};

const getWaitlistUser = async (email: string): Promise<WaitlistUser> => {
  const urlSearchParams = new URLSearchParams({
    email,
    api_key: process.env.NEXT_PUBLIC_WAITLIST_API_KEY,
  });
  return await fetch(
    "https://api.getwaitlist.com/api/v1/waiter?" + urlSearchParams,
    {
      method: "GET",
    }
  )
    .then(async (response) => {
      if (!response.ok) {
        return await response.json().then((err) => {
          if (err?.error_code === "NO_WAITER_FOUND") {
            return null;
          }

          console.error(`${response.status}: ${err?.error_string ?? err}`);
          throw new ErrorWithCode(ERROR_CODES.waitlistRequestFailed);
        });
      }

      return await response.json();
    })
    .catch((err) => {
      if (err instanceof ErrorWithCode) {
        throw err;
      }

      console.error(err);
      throw new Error(`Can't connect to the internet.`);
    });
};

export {
  addUser,
  updateUser,
  updateUserLastPost,
  getUser,
  checkUsernameExists,
  isUserOnboarded,
  doesUserExist,
  addToWaitlist,
  getWaitlistUser,
};
